import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    SiPostman, SiDiscord, SiSpotify, SiNeovim, SiZorin,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiNeovim />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDiscord />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpotify />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiZorin />
      </Col>
    </Row>
  );
}

export default Toolstack;
